.works{
  position: absolute;
  top:0px;
  left:0px;
  width:100.1vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(5, 20vw);
  grid-auto-rows: 25%;
  grid-auto-flow: row;
  z-index: 50;
  //background-color: #fff;
  a{
    display: block;
    position: relative;
    overflow: hidden;
  }

  &_blank{
    pointer-events: none;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}