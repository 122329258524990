.contact{
    position: absolute;
    top:0px;
    left:20vw;
    width:80vw;
    height: 100vh;
    overflow:hidden;
    z-index: 50;
    margin-left: 1px;
    //background-color: #fff;
  &_bg{
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    height: 100%;
    //background-color: #ffffff;
  }
  &_img{
    position: relative;
    width: 20vw;
    height: 75vh;

    &_img{
      position: absolute;
      background-size: cover;
      top:0px;
      left:0px;
      width:100%;
      height: 100%;
    }
    &_bg{
      position: absolute;
      background-color: #f7f7f7;
      top:0px;
      left:0px;
      right: auto;
      width:100%;
      height: 100%;
    }
  }
  &_title{
    width: 20vw;
    height: 25vh;
    position: absolute;
    left: 20vw;
    top:0px;
    a{
      display: inline-block;
      position: relative;
    }
    img{
      width:rem(20);
      margin-right: rem(10);
      margin-top: rem(30);
      opacity: 1;
    }
  }
  &_description{
    width: 40vw;
    height: 25vh;
    position: absolute;
    left: 40vw;
    top:0px;
    background-color: #fff;
    font-size: rem(14);
    line-height: 160%;
    color: #919191;
    padding: rem(40);
  }
  &_bloc{
    width: 20vw;
    height: 25vh;
    position: absolute;
    font-family: Gibson;
    font-size: rem(16);
    line-height: 160%;
    &_content{
      position: absolute;
      padding: rem(40);
      bottom:0px;
      left: 0px;
      span{
        display: block;
        font-family: Gibson;
        font-size: rem(26);
        line-height: 100%;
        margin-bottom: rem(10);
      }
    }
  }
  &_location{
    left: 20vw;
    top: 25vh;
  }
  &_metro{
    left: 40vw;
    top: 25vh;
  }
  &_bus{
     left: 60vw;
     top: 25vh;
   }
  &_tel{
    left: 20vw;
    top: 50vh;
  }
  &_mail{
    left: 20vw;
    top: 75vh;
  }
  &_status{
    left: 0px;
    top: 75vh;
    font-family:karnak;
    font-size: rem(14);
    line-height: 160%;
    .contact_bloc_content{
      padding: rem(25)  rem(30);
    }
  }
  &_map{
    width: 40vw;
    height: 50vh;
    position: absolute;
    top: 50vh;
    left:40vw;
    overflow: hidden;
    iframe{
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &_img{
      position: absolute;
      background-size: cover;
      top:1px;
      left:0px;
      width:100%;
      height: 100%;
    }
    &_bg{
      position: absolute;
      background-color: #f7f7f7;
      top:0px;
      right:0px;
      left: auto;
      width:100%;
      height: 100%;
    }
  }
  &_lines {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 15;
    div {
      position: absolute;
      background-color: #E1E1E1;
      top: 0px;
      left: 0px;
      z-index: 15;
    }
    div.contact_lines_vert_1 {
      width: 1px;
      height: 25vh;
      left: 20vw;
      top: 75vh;
    }
    div.contact_lines_vert_2 {
      width: 1px;
      height: 50vh;
      left: 40vw;
      top: 0;
    }
    div.contact_lines_vert_3 {
      width: 1px;
      height: 25vh;
      left: 60vw;
      top: 25vh;
    }

    div.contact_lines_hori_1 {
      width: 40vw;
      height: 1px;
      top: 25vh;
      left: 40vw;
    }
    div.contact_lines_hori_2 {
      width: 20vw;
      height: 1px;
      top: 50vh;
      left: 20vw;
    }
    div.contact_lines_hori_3 {
      width: 20vw;
      height: 1px;
      top: 75vh;
      left: 20vw;
    }
  }
}