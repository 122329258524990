.about{
  position: absolute;
  top:0px;
  left:20vw;
  width:80vw;
  height: 100vh;
  overflow-y:auto;
  overflow-x: hidden ;
  display: grid;
  grid-template-columns: repeat(2, 40vw);
  z-index: 50;
  margin-left: 1px;
  img{
    display: block;
    width: 100%;
  }
  &_img{
    height: 100vh;
    &_img{
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      opacity: 0;
    }
    &_bg{
      position: absolute;
      bottom:0px;
      left:0px;
      width:100%;
      height: 0%;
      background-color: #f7f7f7;
    }
  }

  &_bloc{
    padding:rem(40);
    font-size: rem(15);
    line-height: 160%;
    border-bottom: 1px solid #E1E1E1;
    &_black{
      background-color: #000;
      color:#fff;
      border-bottom: none;

      overflow: auto;
      strong{
        font-family: gibson;

      }
    }
  }
  &_titre{
    text-transform: uppercase;
    text-align: right;
    font-family: gibson;
    font-weight: 700;
    font-size: rem(46);
    line-height: 100%;
  }
  &_l {
    width: calc(40vw - 1px);
    position: relative;
    &_bg{
      position: absolute;
      top:0px;
      left:0px;
      width:100%;
      height: 100%;
      background-color: #fff;
    }
    &_content{
      position: relative;
    }
    &.about_grid{
      height:25vh;
      &:after{
        display: none;
      }
      .logo{
        bottom:rem(-25);
        left:rem(-7);
      }
    }
  }
  &_r {
    width: calc(40vw - 1px);
    position: relative;
    &_bg{
      position: absolute;
      top:0px;
      left:0px;
      width:100%;
      height: 100%;
      background-color: #fff;
    }
    &_content{
      position: relative;
    }
  }
  &_top{
    height: 25vh;
    width:100%;
    position: relative;
    border-bottom: 1px solid #E1E1E1;
    overflow: hidden;
    .logo {
      right: 30%;
      top: rem(-100);
    }
  }
  &_quote{
    width:100%;
    border-bottom: 1px solid #E1E1E1;
    position: relative;

    &_l {
      width: 20vw;
      border-right: 1px solid #E1E1E1;
      padding: rem(40);
      font-size: rem(20);
      line-height: rem(26);
      font-weight: 500;
      font-family: gibson;
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      top:50%;
      left:50%;
      width:50%;
      height:1px;
      background-color:#E1E1E1 ;
    }

  }
  &_grid{
    overflow: hidden;
    &:after{
      content: '';
      display: block;
      position: absolute;
      top:0%;
      left:0%;
      width:100%;
      height:1px;
      z-index: 10;
      background-color:#E1E1E1 ;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      top:0%;
      left:50%;
      width:1px;
      height:100%;
      z-index: 10;
      background-color:#E1E1E1 ;
    }
    .logo{
      position: absolute;
      bottom:rem(-6);
      left:rem(-5);
      z-index: 15;
    }
  }
  &_contact{
    background-color: #000;
    color:#fff;
    border-bottom: none;
    height: 25vh;
    width: 100%;
    overflow: auto;
    span{
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      font-family: gibson;
      font-size: rem(40);
      line-height: 120%;
      white-space: nowrap;
      display: block;

    }
  }
  &_img_bottom{
    height: 50vh;
    width:40vw;
    background-size: cover;
    background-position: center;
  }
}