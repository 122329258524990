.legals{
  position: absolute;
  top:0px;
  left:20vw;
  width:80vw;
  height: 100vh;
  overflow:hidden;
  z-index: 50;
  margin-left: 1px;
  &_btn{
    font-weight: bold;
    display:inline-block;
  }
  &_title {
    width: 20vw;
    height: 25vh;
    position: absolute;
    left: 0vw;
    top: 0px;
    .title_content{
      padding-bottom: 10px;
    }
  }
  &_content{
    width: 60vw;
    height: 100vh;
    position: absolute;
    left: 20vw;
    top: 0px;
    background-color: #fff;
    padding: rem(50);
    line-height: rem(30);
    overflow-y: auto;
    table{
      border-spacing : 0;
      border-collapse : collapse;
      td{
        vertical-align: top;
        padding:rem(20);
        border: 1px solid #E1E1E1;

      }
    }
  }
}