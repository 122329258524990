.work{
  position: absolute;
  top:0px;
  left:20vw;
  width:80vw;
  height: 100vh;
 //overflow:hidden;
  z-index: 1000;
  margin-left: 1px;
  &_back{
    position: absolute;
    left: -20vw;
    width: 20vw;
    height: 25vh;
    top: 50vh;
    display: block;
    div{
      position:absolute;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
      height: 55%;
      overflow: hidden;
      img{
          max-width: 100%;
          max-height: 100%;
          opacity:0;
          display: block;
      }
      &.work_back_bg{
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        height: 0%;
        background-color: #000;
        transform: translate(0%, 0%);
      }
    }

    span{
      display: block;
      position: absolute;
      left: 0px;
      top:50%;
      transform: translateY(-50%);
      font-family: gibson;
      font-size: rem(20);
      font-weight: 500;
      text-transform: uppercase;
      color:#fff;
    }
  }
  .fslightbox-container{
    background: none;
    background-color:rgba(255,255,255,0.95) ;
  }
  .fslightbox-slide-btn,.fslightbox-toolbar{
    background: none;
    background-color: #000;
  }
    &_content{
    position: relative;
    width: 100%;
    display: grid;

    padding-top: 100vh;
    overflow-x:hidden;
    overflow-y:auto;
    height: 100vh;
  }
  &_img{
    width: 100%;
    height: 100vh;
    position: absolute;
    &_bg{
      position: absolute;
      bottom:0px;
      left:0px;
      width:100%;
      height: 0%;
      background-color: #f7f7f7;
    }
    &_img{
      opacity:0;
      position: absolute;
      top:0px;
      left:0px;
      width:100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
    }
  }
  &_title{
    width:40vw;
    height: 25vh;
    position: relative;
    margin-top: -25vh;
    &_bg{
      position: absolute;
      bottom:0px;
      left:0px;
      width:100%;
      height: 0%;
      background-color: #fff;
    }
    &_content{
      position: absolute;
      top:50%;
      left:0px;
      //width: 80%;
      padding: rem(50);
      transform: translateY(-50%);
      span{
        font-family: Gibson;
        font-size: 12px;
        line-height: 180%;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        font-weight: 500;
        display: block;
        margin-left: rem(2);
      }
      font-family: Gibson;
      font-size: rem(40);
      line-height: 100%;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
  &_detail{
    display: grid;
    grid-template-columns: repeat(5, 20vw);
    grid-auto-flow: row;
    border-top: 1px solid #E1E1E1 ;

    background-color: #fff;
    &_l{
      border-right: 1px solid #E1E1E1 ;
      min-height: 25vh;
      padding: rem(30);
      span{
        font-family: Gibson;
        font-size: rem(12);
        line-height: 180%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #A4A4A4;
        display: block;
      }
      font-family: Gibson;
      font-size: rem(16);
      line-height: 160%;
      align-items: center;
      color: #231F20;
    }
    &_r {
      width: 40vw;
      padding: rem(30) rem(45);
      font-size: rem(14);
      line-height: 160%;

    }
  }
  &_gallery{
    width: 80vw;
    height: 75vh;
    position: relative;
    background-color: #fff;
    z-index: 10;
    &_slider{

      width:60vw;
      height: 50vh;
      background-size: cover;
      background-position: center center;
    }
    .swiper-container {
      height: 50vh;
      width: 100%;
      overflow: visible;
      &:after{
        content: "";
        background-color: #fff;
        width: 20vw;
        height: 50vh;
        position: absolute;
        left: 100%;
        top:0;
        z-index: 5;
      }
    }
    .swiper-slide {
      background-size:cover;
      cursor: zoom-in;
      background-position: center center;
    }
    .swiper-button-next{
      position: absolute;
      background-color: #fff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
      right:rem(-35);

      top: 39vh;
      z-index: 10;
      cursor: pointer;
      width: rem(35);
      height:rem(35);
      background-image: url('/arrow_r.svg');
      background-size: rem(7);
    }
    .swiper-button-prev{
      position: absolute;
      background-color: #fff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
      right:0;
      left: auto;
      top: 39vh;
      z-index: 10;
      cursor: pointer;
      width: rem(35);
      height:rem(35);
      background-image: url('/arrow_l.svg');
      background-size: rem(7);
    }
    .swiper-pagination{
      font-family: Gibson;
      font-size: 1rem;
      line-height: 160%;
      .swiper-pagination-current{
        margin-right: rem(-3);
      }
      .swiper-pagination-total{
        margin-left: rem(-3);
      }
      position: absolute;
      right: 0px;
      top: 100%;
      text-align: right;
      padding-right: rem(15);
      padding-top: rem(10);
    }
    &_r{
      position: absolute;
      right: 0px;
      top: 0px;
      width:20vw;
      height: 50vh;
      border-bottom: 1px solid #E1E1E1 ;
      pointer-events: none;
      &:after{
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 50%;
        background-color: #E1E1E1;
      }
    }
    &_bottom{
      width: 60vw;
      height: 25vh;
      font-family: Gibson;
      font-size: rem(16);
      line-height: 160%;
      align-items: center;
      text-align: right;
      padding: rem(10) rem(15);
      border-right: 1px solid #E1E1E1 ;
    }
  }
  &_more {
    width: 80vw;
    height: 75vh;
    position: relative;
    background-color: #fff;
    border-top: 1px solid #E1E1E1;
    &_title {
      span{
        position: absolute;
        bottom:0px;
        left: 0px;
        padding: rem(30);
      }
      div{
        display: inline-block;
        font-family: Gibson;
        font-size: rem(12);
        line-height: 180%;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        font-weight: 500;
        margin-left: rem(20);
      }
      position: relative;
      width: 40vw;
      height: 25vh;
      font-family: Gibson;
      font-size: rem(40);
      line-height: 120%;
      border-right: 1px solid #E1E1E1;
    }
    &_list{
      position: relative;
      .works_card{
        display: block;
        float: left;
        width: 20vw;
        height: 50vh;
        border-right: 1px solid #E1E1E1;
        &_bg{
          height: 25vh;
          position: relative;
          z-index: 10;
        }
        &_bg_black{

          top: 25vh;
          height: 25vh;
          width: 100%;
          background-color: #fff;
        }
        &_content{
          display: block;
          color:#000;
        }
        &:nth-child(3){
          border-right: 0px;
        }
        &:hover{
          .works_card_content{
            color:#fff;
          }
          .works_card_bg_black{
            background-color: #000;
          }
        }
      }
    }
    &_r{
      position: absolute;
      right: 1px;
      top: 0px;
      width:20vw;
      height: 75vh;
      border-left: 1px solid #E1E1E1 ;
      &:before{
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 50vh;
        background-color: #E1E1E1;
      }
      &:after{
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 25vh;
        background-color: #E1E1E1;
      }
    }
  }
}

.ril__outer{
  background-color: rgba(255,255,255,0.95)!important;
}
.ril__navButtonPrev, .ril-next-button{
  background-color: #000!important;
  opacity:1!important;
}
.ril__caption, .ril__toolbar{
  background-color: transparent!important;
}
.ril__toolbarRightSide{
  background-color: #000!important;
  padding-left: 20px!important;
  height: 60px!important;
}