.credits{
  position: absolute;
  top:0px;
  left:0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  visibility:hidden;
  &_bg{
    position: absolute;
    top:0px;
    left:0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.90);
  }
  &_con{
    background-color: #fff;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    padding: rem(100);
  }
  &_btn{
    font-weight: bold;

    cursor: pointer;
    display: inline-block;
  }
  &_close{
    background-color: #000;
    color:#fff;
    position: absolute;
    right: -1px;
    top:-1px;
    padding: rem(30);
    float: right;
    cursor: pointer;
    img{
      width: rem(40);
    }
  }
}