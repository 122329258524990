@media screen and (max-width: 640px) {


  body, html{
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .menu_mobile{
    display: block;

  }
  .menu_grid, .menu, .app, #root{
    grid:none;
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    overflow: hidden;
    display:block;
  }
  .menu_grid{
    a{
      width:50%;
      float: left;
      height: 25%;
      position: relative;
      .menu_grid_bloc{
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
  }
  .menu_logo{
    width: 50%!important;
    padding: 0px;

    a{
      height: 100%;
      width: 100%;
    }
  }
  .menu_btn{
    display: none;
  }
  .menu_logo_4{
    display: none;
  }
  .menu_logo_5{
    display: none;
  }
  .menu_grid_bloc{
    width:100%;
    height:25%;
    display: none;

  }
  .menu_hover, .menu_logo
  {
    display: block;
  }
  .menu_lines_vert{
    display: none;
  }
  .menu_lines div.menu_lines_vert_1{
    left:50vw;
    display: block;
  }
  .menu_lines{
    height: 100%;
  }
  .menu_lines_hori_1{
    top: 25%!important;
  }
  .menu_lines_hori_2{
    top: 50%!important;
  }
  .menu_lines_hori_3{
    top: 75%!important;
  }
  .about{
    width: 100%;
    left:0px;
    display: block;
    -webkit-overflow-scrolling: touch;
    padding-bottom: rem(150);
    margin-left: 0px;
  }
  .about_l, .about_r{
    width: 100%;
    float: left;
  }
  .about_quote_l{
    width: auto;
  }
  .about_quote:after{
    display: none;
  }
  .about_img_bottom{
    width: 100%;
  }
  .works{
    grid-template-columns: repeat(2, 50%);
    grid-auto-rows: 20%;
    -webkit-overflow-scrolling: touch;
    padding-bottom: rem(70);
  }
  .works_blank{
    display: none;
  }
  .work{
    width: 100%;
    left:0px;
    display: block;
    -webkit-overflow-scrolling: touch;
    padding-bottom: rem(70);
    margin-left: 0px;
  }
  .work_title{
    width: 90%;
    height: auto;
    margin-top: 0vh;
  }
  .work_content{
    padding-top: 100%;
    width: 102%;
  }
  .work_title_content{
    position: relative;
    transform:none;
    top:0;
    left:0px;
    height: auto;
  }
  .work_gallery{
    width: 100%;
    &_slider{
      width: 80vw;
    }
  }
  .work_detail{
    grid-template-columns: repeat(2,50%);
    &_r{
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 2;
      width: 100%;
      border-top: 1px solid #E1E1E1;
    }
  }
  .work_more{
    width: 100%;
    height: 100vh;
  }
  .work_more_title{
    width: 80vw;
  }
  .work_more_list .works_card{
    width: 50%;
    height: 60vh;
  }
  .work_more_r{
    display: none;
  }
  .work_more_list{
    overflow: auto;
    clear: both;
    .work_first{
      display: none;
    }
  }
  .works_card_content{
    position: relative;
  }
  .news{
    width: 100%;
    left: 0px;
    grid-template-columns: repeat(5, 30vw);
    &_l{
      width: 30vw ;
      -webkit-overflow-scrolling: touch;
      padding-bottom: rem(170);
    }
    &_r{
      width: 70vw;
      margin-top: rem(120);
      -webkit-overflow-scrolling: touch;
      padding-top: rem(20);
      overflow-y: auto;
      padding-bottom: rem(170);
    }
  }
  .news_page_head_content{
    padding: rem(20);
    position: relative;
    top: 0%;
    transform: translateY(-0%);
    height: auto;
  }
  .news_page_title{
    width: 100%;
  }
  .news_page_head{
    height: auto;
  }
  .news_page_content{
    padding:rem(20)
  }
  .title{
    width: 100vw;
  }
  .news_page:after{
    display: none;
  }

  .contact{
    width: 100%;
    left:0px;
    display: block;
    -webkit-overflow-scrolling: touch;
    padding-bottom: rem(170);
    margin-left: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    overflow: auto;
    clear: both;
  }
  .contact_img{
    display: none;
  }
  .contact_title{
    width: 100%;
    height: rem(120);
    left: 0px;
    position: relative;

  }
  .contact_description{
    position: relative;
    left: 0px;
    width: 100%;
  }
  .contact_bloc{
    position: relative;
    width:50%;
    float: left;
    left: 0px;
    top:0px;
  }
  .contact_map{
    display: none;
  }
  .contact_bg{
    background-color: #fff;
    height: 150%;
  }
  .contact_title img{
    display: block;
    float: right;
  }
  .contact_title a{

    margin-top: -35px;
    display: block;
  }
  .legals{
    left: 0px;
    width: 100vw;

  }
  .legals_title{
    position: relative;
  }
  .legals_content{
    position: relative;
    -webkit-overflow-scrolling: touch;
    left: 0;
    width: 100vw;
    padding-bottom: rem(170);
    height: 60vh;

  }
}