.menu {
  &_lines {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 15;
    div {
      position: absolute;
      background-color: #E1E1E1;
      top: 0px;
      left: 0px;
      z-index: 15;
    }
    div.menu_lines_vert_1 {
      width: 1px;
      height: 100vh;
      left: 20vw;
    }
    div.menu_lines_vert_2 {
      width: 1px;
      height: 100vh;
      left: 40vw;
    }
    div.menu_lines_vert_3 {
      width: 1px;
      height: 100vh;
      left: 60vw;
    }
    div.menu_lines_vert_4 {
      width: 1px;
      height: 100vh;
      left: 80vw;
    }
    div.menu_lines_hori_1 {
      width: 100vw;
      height: 1px;
      top: 25vh;
    }
    div.menu_lines_hori_2 {
      width: 100vw;
      height: 1px;
      top: 50vh;
    }
    div.menu_lines_hori_3 {
      width: 100vw;
      height: 1px;
      top: 75vh;
    }
  }
  &_grid {
    display: grid;
    grid: repeat(4, 25vh) / auto-flow 20vw;
    a{
      text-decoration: none;
    }
    &_bloc {
      width: 20vw;
      height: 25vh;
      overflow: hidden;
      position: relative;
      font-weight: 100;
      font-size: rem(12);
      padding: rem(30) rem(30);
      line-height: 170%;
      span{
        width: 80%;
        display: block;
      }
      &.menu_baseline {
        padding: 0px;
        font-size: rem(30);
        line-height: 100%;
        font-family: 'gibson';
        font-weight: 500;
        overflow: visible;
        z-index: 50;
        max-width: 100%;
        div {
          position: absolute;
          padding: rem(20);
          max-height: 75vh;
          max-width: 100%;
          overflow-x: hidden;
          background-color: #fff;
          left: 1px;
          right: 1px;
        }
      }
    }
  }
  &_btn {
    z-index: 100;
    //background-color: #fff;
    //border-top: 1px solid #E1E1E1;
    display:block;
    position: relative;
    cursor: pointer;
    &_bg{
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #fff;
      left:0;
      border-top: 1px solid #E1E1E1;
      visibility: hidden;
    }

    &_content{
      position:absolute;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
      height: 55%;
      overflow: hidden;
      img{
        max-width: 100%;
        max-height: 100%;
        opacity:0;
        display: block;
      }
      span{
        position:absolute;
        top:50%;
        left:0%;
        transform: translate(0%, -50%);
        position: absolute;
        font-family: gibson;
        font-size: rem(20);
        font-weight: 500;
        text-transform: uppercase;
      }
      &_burger{
        position:absolute;
        top:50%;
        right:0%;
        transform: translate(0%, -50%);
        width: rem(20);
      }
    }
  }

  &_logo {
    background-color: #fff;
    z-index: 100;

    img {
      position: absolute;
      max-width: 55%;
      max-height: 55%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &_main {
      overflow: visible;
      a{
        position: relative;
        display: block;
      }
    }
    &_1 {
      .logo {
        position: absolute;
        bottom: rem(-3);
        left: 0px;
        .logo_baseline_top {
          font-size: rem(104) !important;
        }
      }
    }
    &_2 {
      .logo {
        position: absolute;
        top: rem(-2);
        left: -20vW;
        .logo_baseline_top {
          font-size: rem(104) !important;
        }
      }
    }
    &_3 {
      .logo {
        position: absolute;
        bottom: rem(-20);
        left: 2vw;
        .logo_firstname {
          font-size: rem(120) !important;
        }
      }
    }
    &_4 {
      position: absolute;
      left: 80vw;
      top: 75vh;
      width: 25vw;
      z-index: 16;
      .logo {
        margin-left: 1px;
        position: absolute;
        left: 0vw;
        bottom: 0px;
        overflow: hidden;
        height: 75vh;
        width: 100%;
        .logo_lastname {
          transform-origin: top left;
          transform: rotate(-90deg);
          font-size: rem(150) !important;
          position: absolute;
          bottom: -15vh;
          left: rem(-35);
        }
      }
    }
    &_5 {
      position: absolute;
      left: 0vw;
      top: 50vh;
      width: 20vw;
      z-index: 16;
      .logo {
        width: 100%;
        height: 50vh;
        overflow: hidden;
        left: 0px;
        top: 0px;
        pointer-events: none;
      }

      .logo_content {
        left: -50%;
        top: 100%;
      }
    }
  }
  &_swiper_arrows {
    position: absolute;
    right: rem(0);
    top: rem(5);
    svg {
      width: rem(5);
      path {
        fill: #000;
      }
    }
    &_next {

    }
    &_prev {
      margin-right: rem(25);
    }
  }
  &_items{
    width: 100%;
    transition: margin-left 0.3s ease;
    display: block;
    position: relative;
  }

  &_hover{
    &_bg{
      position: absolute;
      height: 100%;
      width: rem(10);
      background-color: #000;
      left:rem(-10);
      top:0px;
      transition: left 0.3s ease;
    }
    &:hover{
      .menu_hover_bg{
        left:0;
        transition: left 0.2s ease;
      }
      .menu_items{
        transition: margin-left 0.4s  ease;
        margin-left: rem(5);
      }
    }
  }
  &_mobile{
    position: fixed;
    background-color: #000;
    color:#fff;
    height: rem(75);
    width:100%;
    bottom:0px;
    top:auto;
    z-index: 10000;
    display: none;
    span{
      position:absolute;
      top:50%;
      left:40%;
      transform: translate(0%, -50%);
      position: absolute;
      font-family: gibson;
      font-size: rem(20);
      font-weight: 500;
      text-transform: uppercase;
    }
    &_burger{
      position:absolute;
      top:50%;
      right:40%;
      transform: translate(0%, -50%);
      width: rem(20);
    }
  }
}