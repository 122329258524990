@font-face {
  font-family: 'gibson';
  src: url('./fonts/gibson-bold-webfont.woff2') format('woff2'),
  url('./fonts/gibson-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}
@font-face {
  font-family: 'gibson';
  src: url('./fonts/gibson-light-webfont.woff2') format('woff2'),
  url('./fonts/gibson-light-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;

}
@font-face {
  font-family: 'gibson';
  src: url('./fonts/gibson-semibold-webfont.woff2') format('woff2'),
  url('./fonts/gibson-semibold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;

}
@font-face {
  font-family: 'karnak';
  src: url('./fonts/karnakmed-webfont.woff2') format('woff2'),
  url('./fonts/karnakmed-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}