// Medias helpers
$media-xs: "screen and (min-width: 0px) and (max-width: 767px)";
$media-sm: "screen and (min-width: 768px) and (max-width: 991px)";
$media-md: "screen and (min-width: 992px) and (max-width: 1279px)";
$media-lg: "screen and (min-width: 1280px) and (max-width: 1400px)";
$media-bg: "screen and (min-width: 1401px) and (max-width: 1799px)";
$media-max: "screen and (min-width: 1800px)";

$media-sm-min: "screen and (min-width: 768px)";
$media-md-min: "screen and (min-width: 992px)";
$media-lg-min: "screen and (min-width: 1200px)";

html {
  font-size: 2vh;
}
@media screen and (min-height: 800px) {
  html {

    font-size: 18px;
  }
  @media screen and (max-width: 1200px) {
    html {

      font-size: 15px;
    }
  }
}
