body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    background: #fff;
    margin: 0px;
    padding:0px;
    font-family: 'karnak';
    overflow: hidden;

}

*{
  /* Reset. */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  //text-rendering: optimizeLegibility;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  z-index: 1;
}
/* width */
::-webkit-scrollbar {
  width: rem(6);

}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bababa;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
  border-radius: 5px;
}

a{
  color:#000;
  text-decoration: none;

}
a:hover {
    text-decoration: none;
}

select, input, button:focus {
    outline: none;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
    font-family: 'gibson';
    font-weight: 500;
}
h2{
  font-size: rem(26);
  margin-bottom: rem(15);
  line-height: 100%;
}
h5{
  font-size: rem(12);
}
.date{
  font-family: 'gibson';
  font-size: rem(11);
  font-weight: 100;
  color:#909090;
  margin-top: rem(-5);
  margin-bottom:rem(7);


}
img {
    max-width: 100%;
}
p{
  margin: rem(20) 0px;
}
h1{
  margin: rem(30) 0px 0px 0px;
}


.title{
  width:100%;
  height: 25vh;
  position: relative;
  padding: rem(40) rem(30);
  span{
    display: block;
  }
  &_content{
    color:#fff;
    position: relative;
    font-family: gibson;
    font-weight: 500;
    font-size: rem(40);
    line-height: 95%;
    overflow: hidden;
    &_col{
      font-size:rem(30) ;
    }
  }
  &_bg{
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    height: 100%;
    background-color: #000;
  }
}

.preload{
  opacity:0;
}
.generic_close{
  position: absolute;
  top: 0px;
  right: 0px;
  display:block;
  background-color: #000;
  padding: rem(20);
  z-index: 10;
  img{
    width: rem(40);
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


