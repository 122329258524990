.works_card{
  position: relative;


  &_bg{
    background-size: cover;
    background-position: center center;
    position: absolute;
    width: 100%;
    height: 101%;
    top:0px;
    left: 0px;

  }
  &_bg_black{
    position: absolute;
    width: 0%;
    height: 101%;
    top:0px;
    left: 0px;
    background-color: transparent;

  }
  &_content{
    position: absolute;
    width:100%;
    bottom:0px;
    left: 0px;
    padding:rem(20);
    color:#fff;
    &_title{
      font-family: gibson;
      text-transform: uppercase;
      font-size: rem(18);
      font-weight: 700;
      width: 60%;
    }
    &_location{
      font-family: gibson;
      font-weight: 500;
      font-size: rem(12);
      margin-top:rem(5);
      width: 80%;
    }
  }
  //&:hover{
  //  .works_card_content{
  //    display:block;
  //  }
  //  .works_card_bg_black{
  //    display:block;
  //  }
  //
  //}
}