.news{
  position: absolute;
  top:0px;
  left:20vw;
  width:80vw;
  height: 100vh;
  overflow:hidden;
  display: grid;
  grid-template-columns: repeat(5, 20vw);
  grid-auto-rows: 25%;
  grid-auto-flow: row;
  z-index: 50;
  margin-left: 1px;
  &_l{
    width: calc(20vw - 1px);
    height: 100vh;
    position: relative;
    &_bg{
      position: absolute;
      top:0px;
      left:0px;
      width:100%;
      height: 100%;
      background-color: #fff;
    }
  }
  &_r{
    width: 60vw;
    height: 100vh;
    position: relative;
    &_bg{
      position: absolute;
      top:0px;
      left:0px;
      width:100%;
      height: 100%;
      background-color: #fff;

    }
  }
  &_blank{
    pointer-events: none;
  }
  &_page{
    position: relative;
    height: 100vh;
    overflow-y: auto;
    opacity: 0;
    &:after{
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 25vh;
      background-color: #E1E1E1;
    }
    &_head{
      height: 25vh;
      width:100%;
      position: relative;
      &_content{
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        width: 100%;
        padding:rem(60) rem(50);
      }
      .date{
        font-size: rem(11);
        text-transform: uppercase;
        font-weight: 500;
      }
    }
    &_title{
      font-size: rem(40);
      font-weight: 400;
      font-family: gibson;
      width:70%;
      line-height: 95%;
    }
    &_content{
      padding:rem(50);
      line-height: 160%;
      iframe{
        width: 100%;
        min-height: 400px;
      }
      &_img{
        margin-bottom: rem(20);
        width: 100%;
        position: relative;
        img{
          display:block;
        }
        &_bg{
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          height: 0%;
          background-color: #FAFAFA;
        }
      }
    }
  }
  &_list{
    position: relative;
    height: 75vh;
    overflow-y: auto;
    &_item{
      padding:rem(20);
      display: block;
      position: relative;
      background-color: #FAFAFA;
      &_bg{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: #F4F3F3;
      }
      &_title{
        font-size: rem(16);
        font-weight: 400;
        line-height: 130%;
        font-family: gibson;
        width:80%;
        position: relative;
      }
      .date{
        position:relative;

      }
    &:hover{
        background-color: #F4F3F3;
    }
    }
  }
}