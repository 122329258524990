.logo{
  position: absolute;
  text-transform: uppercase;
  font-family: 'gibson';
  white-space:nowrap;
  &_content{
    position: absolute;
    left:0px;
    top:0px;
  }
  &_firstname{
    font-size: rem(188);
    line-height: 80%;
    font-weight: 700;
  }
  &_lastname{
    font-size: rem(249);
    line-height: 114%;
    font-weight: 700;
    margin-left:rem(-3);
  }
  &_baseline_top{
    font-size: rem(160);
    font-weight: 100;
    line-height: 75%;
    margin-left:rem(3);
  }
  &_baseline_bottom{
    font-size: rem(153);
    font-weight: 100;
    line-height: 100%;
  }
}