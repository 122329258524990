.intro{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  visibility: hidden;

  .logo{
    width: 100%;
    height: 100%;
    position:absolute;
  }
  .logo_content{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%) scale(0.3) ;
    span{
      display: block;
    }
    div{
      overflow: hidden;
    }
  }
}